import React, { useRef, useEffect, useState } from 'react';
import './Home.css';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import Desarrollo from '../assets/derechos.png';
import Tributaria from '../assets/tributaria.png';
import Financiera from '../assets/financieras.png';
import ProyectoSaldos from '../assets/accounts.png';
import ProyectoBCH from '../assets/bch.png';
import politicaTratamiento from '../assets/POLITICA-DE-TRATAMIENTO-DE-DATOS.docx'
import ModalTratDatos from './modal';
import { create_partner, link_relatives } from '../services/servises';

export default function Home() {
  const [showDataModal, setShowDataModal] = useState(false);
  const [dataChecked, setDataChecked] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showFamilyForm, setShowFamilyForm] = useState(false);
  const [isFlipping, setIsFlipping] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [idPartner, setIdPartner] = useState('')

  const inicioRef = useRef(null);
  const serviciosRef = useRef(null);
  const proyectosRef = useRef(null);
  const contactoRef = useRef(null);

  const [contactData, setContactData] = useState({
    email: '',
    nombre: '',
    apellidos: '',
    tipoDoc: '',
    numDoc: '',
    email2: '',
    direccion: '',
    celular: '',
    dataChecked: false,
    policyChecked: false,
  });

  const [familyData, setFamilyData] = useState({
    email: '',
    nombre: '',
    apellidos: '',
    tipoDoc: '',
    numDoc: '',
    lugarDefuncion: '',
    parentesco: '',
  });

  const scrollToSection = (sectionRef) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        window.scrollBy({ top: -100, behavior: 'smooth' });
      }, 250);
    }
  };

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleScroll = () => {
    const images = document.querySelectorAll('.service-image, .project-image');
    const contents = document.querySelectorAll('.service-content, .project-content');

    images.forEach(img => {
      if (isElementInViewport(img) && !img.classList.contains('animate-left')) {
        img.classList.add('animate-left');
      }
    });

    contents.forEach(content => {
      if (isElementInViewport(content) && !content.classList.contains('animate-right')) {
        content.classList.add('animate-right');
      }
    });
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isMenuOpen]);

  const scrollToTop = () => {
    const duration = 2000; // Duration in milliseconds (2 seconds)
    const start = window.pageYOffset;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const scroll = () => {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, ((now - startTime) / duration));
      const easeInOutCubic = time < 0.5
        ? 4 * time * time * time
        : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1;

      window.scrollTo(0, start * (1 - easeInOutCubic));

      if (time < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('load', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email: contactData.email2,
      name: contactData.nombre,
      f_lastname: contactData.apellidos.split(" ")[0],
      s_lastname: contactData.apellidos.split(" ")[1] || '',
      doc_type: contactData.tipoDoc,
      doc_status: "vigente",
      street: contactData.direccion,
      vat: contactData.numDoc,
      phone: contactData.celular,
      customer_type: "customer",
      created_by: "example_user"
    };

    console.log("Datos formateados para enviar", data);

    await create_partner(data, async (flag, res) => {
      console.log("Respuesta del servicio crear partner", flag, res);
      if (flag) {

        if (res?.error === 'Ya existe la persona en los clientes.') {
          setIdPartner(res.id)
          Swal.fire({
            title: 'Datos enviados exitosamente',
            text: 'Por favor llenar los datos del familiar',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            background: '#d7e3df',
            iconColor: '#07593B'
          }).then(() => {
            setIsFlipping(true);
            setTimeout(() => {
              setShowFamilyForm(true);
              setTimeout(() => {
                setIsFlipping(false);
              }, 500);
            }, 300);
          });
        } else {
          setIdPartner(res)
          Swal.fire({
            title: 'Datos enviados exitosamente',
            text: 'Por favor llenar los datos de familiar',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            background: '#d7e3df',
            iconColor: '#07593B'
          }).then(() => {
            setIsFlipping(true);
            setTimeout(() => {
              setShowFamilyForm(true);
              setTimeout(() => {
                setIsFlipping(false);
              }, 500);
            }, 300);
          });
        }
      } else {
        Swal.fire({
          title: 'No se realizo el registro',
          text: 'Verifique su conexion a internet y realize el registro nuevamente',
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          background: '#d7e3df',
          iconColor: '#07593B'
        })
      }

    });


  };

  const handleFamilySubmit = async (e) => {
    e.preventDefault();
    console.log("Datos de la familia:", familyData);
    const contactData = {
      client_id: idPartner,
      created_by: "Externo Web",
      relative_id: {
        email: familyData.email,
        name: familyData.nombre,
        f_lastname: familyData.apellidos.split(' ')[0],
        s_lastname: familyData.apellidos.split(' ')[1],
        doc_type: familyData.tipoDoc,
        doc_status: "cancelado_muerte",
        vat: familyData.numDoc,
        street: familyData.lugarDefuncion,
        kinship: familyData.parentesco,
        customer_type: "relative"
      }
    };

    console.log('datos familiar a enviar', contactData);

    await link_relatives(contactData, async (flag, res) => {
      console.log("Respuesta de crear familiar", flag, res);
      if (flag) {
        if (res?.familiar) {
          Swal.fire({
            title: 'Datos enviados exitosamente',
            text: 'Gracias por registrar la información',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            background: '#d7e3df',
            iconColor: '#07593B'
          }).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            title: 'No se realizo el registro del familiar',
            text: 'Verifique su conexion a internet y realize el registro nuevamente',
            icon: 'warning',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            background: '#d7e3df',
            iconColor: '#07593B'
          })
        }
      } else {
        Swal.fire({
          title: 'No se realizo el registro del familiar',
          text: 'Verifique su conexion a internet y realize el registro nuevamente',
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          background: '#d7e3df',
          iconColor: '#07593B'
        })
      }

    });
    // Aquí puedes agregar lógica adicional después del envío del formulario familiar
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFamilyChange = (e) => {
    const { name, value } = e.target;
    setFamilyData(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="home-container">
      <header className="header green-header">
        <div className="logo">
          <img src={require('../assets/vertical.png')} alt="Logo" className="logo-img" />
        </div>
        <button className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <ul>
            <li><a href="#inicio" onClick={() => { scrollToSection(inicioRef); setIsMenuOpen(false); }}>Inicio</a></li>
            <li><a href="#servicios" onClick={() => { scrollToSection(serviciosRef); setIsMenuOpen(false); }}>Nuestros Servicios</a></li>
            <li><a href="#proyectos" onClick={() => { scrollToSection(proyectosRef); setIsMenuOpen(false); }}>Proyectos en Desarrollo</a></li>
            <li><a href="#contacto" onClick={() => { scrollToSection(contactoRef); setIsMenuOpen(false); }}>Contacto</a></li>
          </ul>
        </nav>
      </header>

      <section id="inicio" ref={inicioRef} className="banner">
        <div className="banner-container">
          <div className="banner-logo">
            <img src={require('../assets/logo.png')} alt="Logo" className="imagen banner-logo-img" />
          </div>

          <div className='formulario'>
            <h3 style={{ textAlign: 'center', marginRight:'25%',color: '#07593B', fontSize: '1.5rem', fontWeight: 'bold',  }}>
              {showFamilyForm ? 'Formulario datos del familiar' : 'Formulario datos de contacto'}
            </h3>
            <div className="form-container ">
              <div className={`form-flipper ${isFlipping ? 'flipping' : ''}`}>
                <div className={`form-content ${showFamilyForm ? 'back' : 'front'}`}>
                  {!showFamilyForm ? (
                    <form onSubmit={handleSubmit} className="contact-form">
                      <h3 style={{ marginLeft: '80px', color: '#105a41' }}>Datos de contacto</h3>
                      {/* Campos del formulario de contacto */}
                      <div className="form-group">
                        <input
                          type="text"
                          name="nombre"
                          placeholder="Nombre"
                          required
                          value={contactData.nombre}
                          onChange={handleContactChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="apellidos"
                          placeholder="Apellidos"
                          required
                          value={contactData.apellidos}
                          onChange={handleContactChange}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          name="tipoDoc"
                          required
                          value={contactData.tipoDoc}
                          onChange={handleContactChange}
                        >
                          <option value="">Documento de Identificación</option>
                          <option value="cc">C.C</option>
                          <option value="ti">T.I</option>
                          <option value="ce">C.E</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="numDoc"
                          placeholder="Número del documento"
                          required
                          value={contactData.numDoc}
                          onChange={handleContactChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email2"
                          placeholder="Correo electrónico"
                          required
                          value={contactData.email2}
                          onChange={handleContactChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="direccion"
                          placeholder="Dirección"
                          required
                          value={contactData.direccion}
                          onChange={handleContactChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          name="celular"
                          placeholder="Número de celular"
                          required
                          value={contactData.celular}
                          onChange={handleContactChange}
                        />
                      </div>
                      <div className="form-group" style={{ marginLeft: '40%' }}>
                        <input
                          type="checkbox"
                          checked={dataChecked}
                          onChange={() => setDataChecked(!dataChecked)}
                        />
                        <span className="link" onClick={() => setShowDataModal(true)}>
                          Acepto el tratamiento de datos
                        </span>
                      </div>
                      <button type="submit" className="form-button" disabled={!dataChecked}>
                        Enviar
                      </button>
                    </form>
                  ) : (
                    <form onSubmit={handleFamilySubmit} className="family-form">
                      <h3 style={{ marginLeft: '80px', color: '#105a41' }}>Datos del familiar</h3>
                      {/* Campos del formulario familiar */}
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Correo"
                          required
                          value={familyData.email}
                          onChange={handleFamilyChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="nombre"
                          placeholder="Nombre del familiar"
                          required
                          value={familyData.nombre}
                          onChange={handleFamilyChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="apellidos"
                          placeholder="Apellidos del familiar"
                          required
                          value={familyData.apellidos}
                          onChange={handleFamilyChange}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          name="tipoDoc"
                          required
                          value={familyData.tipoDoc}
                          onChange={handleFamilyChange}
                        >
                          <option value="">Documento de Identificación</option>
                          <option value="cc">C.C</option>
                          <option value="ti">T.I</option>
                          <option value="ce">C.E</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="numDoc"
                          placeholder="Número del documento"
                          required
                          value={familyData.numDoc}
                          onChange={handleFamilyChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="lugarDefuncion"
                          placeholder="Lugar de defunción"
                          required
                          value={familyData.lugarDefuncion}
                          onChange={handleFamilyChange}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          name="parentesco"
                          required
                          value={familyData.parentesco}
                          onChange={handleFamilyChange}
                        >
                          <option value="">Parentesco</option>
                          <option value="hijo">Hijo</option>
                          <option value="conyuge">Cónyuge supérstite</option>
                          <option value="hermano">Hermano</option>
                          <option value="padre">Padre</option>
                          <option value="otro">Otro</option>
                        </select>
                      </div>
                      <button type="submit" className="form-button">
                        Enviar
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="servicios" ref={serviciosRef} className="services-section">
        <h2>NUESTROS SERVICIOS DESTACADOS</h2>
        <div className="services">
          <div className="service">
            <div className="service-image">
              <img src={Desarrollo} alt="Proyectos en Desarrollo" />
            </div>
            <div className="service-content">
              <h3>Proyectos en Desarrollo</h3>
              <p>Asesoría preventiva y especializada en derechos del consumidor financiero. Optimiza tus recursos con nuestra ayuda.</p>
            </div>
          </div>
          <div className="service">
            <div className="service-image">
              <img src={Financiera} alt="Consultorías Financieras" />
            </div>
            <div className="service-content">
              <h3>Consultorías Financieras</h3>
              <p>Asesoramiento en estructuración de portafolios, gestión de activos y más, tanto para PYMES como personas naturales.</p>
            </div>
          </div>
          <div className="service">
            <div className="service-image">
              <img src={Tributaria} alt="Asesoría Tributaria" />
            </div>
            <div className="service-content">
              <h3>Asesoría Tributaria</h3>
              <p>Soluciones en impuestos y retenciones, planeación tributaria para garantizar el cumplimiento y optimización contable.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="proyectos" ref={proyectosRef} className="projects-section">
        <h2>PROYECTOS EN DESARROLLO</h2>
        <div className="projects">
          <div className="project">
            <div className="project-image">
              <img src={ProyectoSaldos} alt="Recuperación de Saldos" />
            </div>
            <div className="project-content">
              <h3>Recuperación de Saldos a Favor en el Sistema Financiero</h3>
              <p>¿Sabías que podrías tener dinero no reclamado? Contáctanos para recuperar saldos a favor en el sistema financiero.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="contacto" ref={contactoRef} className="contact-section">
        <h2>Contáctanos</h2>
        <p>Estamos disponibles en varias ciudades:</p>
        <div className="contact-info">
          <div className="city">
            <h3>Manizales</h3>
            <p>Carrera 27 A No. 66-30 Oficina 1005</p>
            <p>Tel: +57 6 8911707</p>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248.3943076866282!2d-75.49001136607562!3d5.053581988657373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e47657b00b21237%3A0xfdee939a9943c781!2sCra%2027A%20%2366%2030%2C%20Manizales%2C%20Caldas!5e0!3m2!1ses-419!2sco!4v1727909341578!5m2!1ses-419!2sco"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Mapa Manizales"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <footer className="green-header">
        <div className="footer-content">
          <img src={require('../assets/logo.png')} alt="Logo" className="footer-logo" />
          <p>
            Consulta nuestras <a href={politicaTratamiento} target="_blank" rel="noopener noreferrer">políticas</a> aquí.
          </p>
        </div>
      </footer>

      <ModalTratDatos showDataModal={showDataModal} setShowDataModal={setShowDataModal} />
      <div
        className={`scroll-to-top ${showScroll ? 'visible' : ''}`} onClick={scrollToTop}>
        <i className="arrow up"></i>
      </div>
    </div>
  );
}      
