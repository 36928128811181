import { xmlrpcServices } from "../utilities/xmlrpc";

export const create_partner = async (data, callback) => {
    try {
      const args = [0, data];
      const params = {
        model: 'did.ani.webservice',
        method: 'create_partner',
        args: args,
        kwargs: {}
      };
      console.log("args create_partner", args);
      xmlrpcServices(params, async (err, response) => {
        console.log("response crear partner", err, response);
        if (err) {
          alert("Error: No se obtuvieron datos del servidor.");
          return callback(false);
        }
        return callback(true, response);
      });
    } catch (error) {
      console.error("error al obtener datos del gráfico", error);
    }
  };

  export const link_relatives = async (data, callback) => {
    try {
      const args = [0, data];
      const params = {
        model: 'did.ani.webservice',
        method: 'link_relatives',
        args: args,
        kwargs: {}
      };
      console.log("args crear familiar", args);
      xmlrpcServices(params, async (err, response) => {
        console.log("response crear familiar", err, response);
        if (err) {
          alert("Error: No se obtuvieron datos del servidor.");
          return callback(false);
        }
        return callback(true, response);
      });
    } catch (error) {
      console.error("error al obtener datos del gráfico", error);
    }
  };
