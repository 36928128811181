/* const configcolpruebas = {
    host: 'https://0462-2800-e6-4001-feee-39d8-cb9f-d869-9cff.ngrok-free.app',
    port: '8071',
    database: 'bcan2',
    encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
  } */
 export const OdooConfig = async () => {

 

    const OdooConfig = {
        host: 'https://dydcliente.dydasesorias.com.co',
        port: '',
        database: 'dydcliente',
        encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
      }
      return OdooConfig;
 }

 export const clientSession = { uid:'6', password:'op_dyd' }  
   