import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'; // Importa BrowserRouter
import Routes from './routes.js'; // Asegúrate de que routes.js contiene tus rutas correctamente
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <Router> {/* Envuelve Routes dentro de Router */}
      <Routes />
      <Toaster /> {/* Para mostrar las notificaciones */}
    </Router>
  );
}

export default App;
