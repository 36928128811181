
import { Button, Navbar, Container, Row, Col, Image } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
//import logo from '../../assets/perfil.png'
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faUser, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';


export const Header = ({path, reload}) => {
    const FB_PIXEL_ID = "313798497539699";
    const user = localStorage.getItem('user')
    const logged = localStorage.getItem('uid');
    //const path = props.path
    //console.log("el user menu----", reload);

    useEffect(() => {
        facebookPixel();
        //localStorage.clear();
        //window.location = "*";
        console.log("el user menu----", localStorage.getItem('uid'));
    }, [])


    const facebookPixel = async () => {
        import("react-facebook-pixel")
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.fbq();
                ReactPixel.init(FB_PIXEL_ID);
                ReactPixel.pageView();
            });
        return null;
    }
    const handleClick = () => {
        reload();  // Asegúrate de que reload se llama correctamente
      };
    return (
        <>
            <script id="facebook-pixel">
                {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', ${FB_PIXEL_ID});
                    fbq('track', 'PageView');
                `}
            </script>
            <Col md={12} xs={12} sm={12} lg={12}>
                {/* <Image src={logo}
                    style={{ width: 280, height: 'auto', marginBottom: '0%', marginTop: '2%' }}
                /> */}
            </Col>
            {logged && <div className="userHeader" >
                {/* <div className='back'>
                    <button style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{ width: '50px', height: 'auto', color: '#FF7438' }} />
                    </button>
                </div> */}
                <div className='user'>
                    <div style={{ width: '6%', height: '30%' }}>
                        <FontAwesomeIcon icon={faUser} color='#FF7438' size={40} style={{ width: 25, height: 'auto' }} />
                    </div>
                </div>
                {path && <div className='lock'>
                    <button onClick={handleClick} style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <FontAwesomeIcon icon={faUnlockAlt} style={{ width: '40px', height: 'auto', color: 'white' }} />
                    </button>
                </div>

                }
            </div>}
        </>
    )
}