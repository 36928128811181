import React from 'react';
import './Home.css';
import { Modal, Button } from 'react-bootstrap';
import BackgroundData from '../assets/logo.png'; // Asegúrate de tener esta imagen

const ModalTratDatos = ({ showDataModal, setShowDataModal }) => {


    return (
        <Modal
            show={showDataModal}
            onHide={() => setShowDataModal(false)}
            dialogClassName="modal-data"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Política de Protección y Privacidad de Datos Personales</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    background: `linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), 
                url(${BackgroundData}) center/cover no-repeat`,
                }}
            >
                <div className="principal">
                    <div className="container">
                        <h1>Política de Tratamiento de Datos en Colombia</h1>
                        <section>
                            <p>
                                Con el fin de garantizar y proteger el derecho constitucional que todas las personas tienen a conocer, actualizar, rectificar y suprimir sus datos personales almacenados en bases de datos, se pone a disposición de los titulares de los datos personales la presente política para el uso, administración, transmisión y demás actividades relacionadas con el tratamiento de datos personales.
                            </p>
                            <p>
                                La Sociedad se compromete a proteger la privacidad y la confidencialidad de la información, de acuerdo con la Constitución, la Ley 1581 de 2012, el Decreto 1377 de 2013, el Decreto 1081 de 2015, el Decreto 255 de 2022 y demás normativas vigentes.
                            </p>
                        </section>

                        <section>
                            <h2>2. Alcance</h2>
                            <p>
                                Sus datos personales son necesarios para que la sociedad pueda brindarle un servicio integral. Por ello, le informamos que los datos personales que usted nos suministre o a los que la sociedad tenga acceso en el desarrollo del objeto contractual serán tratados conforme a lo dispuesto en la Política de Tratamiento de Datos Personales.
                            </p>
                            <p>
                                Esta política aplica a todas las operaciones y actividades que involucren el tratamiento de datos personales realizados por DYD ASESORÍAS S.A.S, así como a todos los empleados, contratistas y terceros que manejen información personal en nombre de la empresa.
                            </p>
                        </section>

                        <section>
                            <h2>3. Definiciones</h2>
                            <p>
                                En el desarrollo, interpretación y aplicación de la ley, regulaciones y normatividad vigente, se aplicarán de manera armónica e integral las siguientes definiciones:
                            </p>
                            <ul>
                                <li><strong>Autorización:</strong> Es el consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de sus Datos Personales.</li>
                                <li><strong>Base de Datos:</strong> Conjunto organizado de Datos Personales objeto de Tratamiento, electrónico o no, cualquiera que fuere la modalidad de su formación, almacenamiento, organización y acceso.</li>
                                <li><strong>Dato Financiero:</strong> Información relacionada con obligaciones dinerarias, independientemente de la naturaleza del contrato que les dé origen.</li>
                                <li><strong>Dato Personal:</strong> Información vinculada o que pueda asociarse a una o varias personas naturales o jurídicas determinadas o determinables.</li>
                                <li><strong>Dato Público:</strong> Dato Personal calificado como tal según la ley o la Constitución Política, y que no es semiprivado, privado o sensible.</li>
                                <li><strong>Dato Sensible:</strong> Información que afecta la intimidad del Titular o cuyo uso indebido puede generar discriminación.</li>
                                <li><strong>Encargado del Tratamiento:</strong> Persona natural o jurídica que realice el Tratamiento de Datos Personales por cuenta del Responsable del Tratamiento.</li>
                                <li><strong>Responsable de Tratamiento:</strong> Persona que decida sobre la Base de Datos y/o el Tratamiento de los Datos Personales.</li>
                                <li><strong>Titular:</strong> Persona natural cuyos Datos Personales sean objeto de Tratamiento.</li>
                            </ul>
                        </section>

                        <section>
                            <h2>4. Principios</h2>
                            <p>El tratamiento de datos personales en DYD ASESORÍAS S.A.S se rige por los siguientes principios:</p>
                            <ul>
                                <li><strong>Limitación de la finalidad:</strong> Los datos se recopilan para fines específicos, explícitos y legítimos.</li>
                                <li><strong>Minimización de datos:</strong> Solo se recopilan los datos necesarios para el cumplimiento de la labor encomendada.</li>
                                <li><strong>Exactitud:</strong> Los datos personales deben ser precisos y mantenerse actualizados.</li>
                                <li><strong>Limitación del plazo de conservación:</strong> Los datos se conservarán solo durante el tiempo necesario para cumplir con los fines.</li>
                                <li><strong>Integridad y confidencialidad:</strong> Los datos serán tratados con medidas de seguridad adecuadas.</li>
                            </ul>
                        </section>

                        <section>
                            <h2>5. Tratamiento de Datos Personales</h2>
                            <p>Los Datos Personales serán tratados para las siguientes finalidades:</p>
                            <ul>
                                <li>Gestionar información necesaria para el cumplimiento de las obligaciones de la Sociedad.</li>
                                <li>Prestar servicios conforme a las necesidades particulares de los clientes.</li>
                                <li>Controlar y prevenir actividades ilícitas como fraude, lavado de activos, etc.</li>
                                <li>Permitir el acceso a datos personales a auditores o terceros.</li>
                                <li>Gestionar el archivo, actualización de sistemas y protección de la información.</li>
                                <li>Transmitir datos a terceros con quienes se hayan celebrado contratos.</li>
                            </ul>
                        </section>

                        <section>
                            <h3>5.1 Tratamiento de Datos Personales de Niñas, Niños y Adolescentes</h3>
                            <p>La sociedad Tratará los Datos Personales de un menor de 18 años, siempre que exista el consentimiento previo y expreso
                                de padres o tutores legales. En estos casos, los padres o tutores legales pueden cambiar o revocar la Autorización ta
                                 como se describe en esta Política. Adicionalmente, el Tratamiento de los Datos Personales de niñas, niños y adolescentes, 
                                umplirá con los siguientes parámetros y requisitos:</p>
                             <ul>
                                <li>El Tratamiento responderá y respetará el interés superior de los niños, niñas y adolescentes.</li>
                                <li>En todo momento se asegurará el respeto de sus derechos fundamentales.</li>
                                <li>El niño, niña o adolescente será escuchado, y su opinión será valorada teniendo en cuenta la madurez, autonomía y capacidad para entender el asunto.</li>
                            </ul>
                        </section>
                        <section>
                            <h3>5.2 TRATAMIENTO DE DATOS SENSIBLES</h3>
                            <p>La sociedad podrá solicitar los Datos Sensibles que expresamente se mencionarán en cada Autorización.
                                En todo caso, la sociedad observará estrictamente las limitaciones legales al Tratamiento de Datos Sensibles,
                                sometiendo a Tratamiento los Datos Sensibles únicamente cuando el Titular haya otorgado su Autorización, salvo
                                en los casos en que la ley no requiera de dicha Autorización. Cuando la sociedad solicite Datos Sensibles, le
                                informará qué tipo de Datos Personales tienen esta categoría y no condicionará, en ningún caso, ninguna actividad a la entrega de Datos Sensibles.
                                Los Datos Sensibles serán tratados con la mayor diligencia posible y con los estándares de seguridad más altos. El acceso limitado a los Datos
                                Sensibles será un principio rector para salvaguardar la privacidad de esos Datos Personales y, por lo tanto, solo el personal autorizado podrá tener acceso a ese tipo de información.</p>
                        </section>

                        <section>
                            <h2>6. DERECHOS DEL TITULAR DE LOS DATOS PERSONALES</h2>
                            <p>De acuerdo con la ley, los Titulares de Datos Personales tienen los siguientes derechos:</p>
                            <ul>
                                <li><strong>ACTUALIZACIÓN: </strong> Conocer, actualizar y rectificar sus datos personales frente a la Sociedad o los Encargados del Tratamiento. Este derecho se puede ejercer respecto de datos parciales, inexactos, incompletos, fraccionados, que puedan inducir a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.</li>
                                <li><strong>PRUEBA: </strong> Solicitar prueba de la autorización otorgada a la Sociedad, salvo cuando no sea necesaria.</li>
                                <li><strong>INFORMACIÓN: </strong>Solicitar a la Sociedad o al Encargado del Tratamiento información sobre el uso que se ha dado a sus datos personales, y recibir respuesta a dicha solicitud.</li>
                                <li><strong>QUEJAS Y RECLAMOS: </strong>Presentar quejas ante la Superintendencia de Industria y Comercio por infracciones a la ley, una vez agotado el trámite de consulta o reclamo ante la Sociedad.</li>
                                <li><strong>REVOCACIÓN: </strong>Revocar la autorización otorgada y Solicitar la eliminación de sus datos personales de las bases de datos, siempre y cuando no exista una obligación legal o contractual que justifique la permanencia de los datos.</li>
                                <li><strong>ACCESO: </strong>Acceder de manera gratuita a los datos personales que han sido objeto de tratamiento.</li>
                                <li><strong>CONOCIMIENTO: </strong>Ser informado de cualquier modificación en los términos de esta Política antes de su implementación. También ser informado sobre la dependencia o persona responsable dentro de la Sociedad para presentar quejas, consultas, reclamos
                                    y otras solicitudes relacionadas con sus datos personales. Los titulares podrán ejercer estos derechos presentando su documento de identificación o una copia del mismo. En el caso de menores de edad, podrán ejercer sus derechos directamente
                                    o a través de sus padres o tutores, quienes deberán acreditar su autoridad mediante la documentación pertinente. También podrán ejercer estos derechos los causahabientes del titular, su representante o apoderado con la respectiva acreditación, y quienes hayan estipulado un beneficio a favor de otro.</li>
                            </ul>
                        </section>

                        <section>
                            <h2>7. OBLIGACIONES DE LA SOCIEDAD.</h2>
                            <ul>
                                <li>Obtener la autorización previa del titular cuando lo exija la normativa aplicable.</li>
                                <li>Clasificar adecuadamente los datos solicitados.</li>
                                <li>Archivar y gestionar la autorización otorgada por el titular.</li>
                                <li>Cumplir con los principios establecidos en esta Política.</li>
                                <li>Atender las consultas, quejas o reclamos presentados por los titulares.</li>
                                <li>Garantizar la seguridad de los datos suministrados mediante procedimientos relacionados con la protección de la información.</li>
                                <li>Cuando la Sociedad actúe como Encargado o cuando terceros tengan acceso a los datos personales, el tratamiento deberá limitarse a las finalidades contempladas para la recolección de dichos datos.</li>
                                <li>Cuando la Sociedad realice transferencias o transmisiones de datos personales de los titulares, solo se llevará a cabo respecto de la información para la cual el titular haya otorgado su autorización expresa, previa e inequívoca.</li>
                                <li>Recibir las solicitudes de los Titulares de Datos Personales, tramitar y responder aquellas que tengan fundamento en la Ley o esta Política.</li>
                                <li>Dar respuesta a los Titulares de los Datos Personales sobre aquellas solicitudes que no procedan de acuerdo con la Ley.</li>
                            </ul>
                        </section>


                        <section>
                            <h2>8. PETICIONES, CONSULTAS Y RECLAMOS</h2>

                            <p>La sociedad dispondrá de mecanismos para que el Titular, sus causahabientes, o los representantes de menores de edad Titulares, formulen consultas respecto de cuáles son los Datos Personales del Titular que reposan en las Bases de Datos de la sociedad. Estos mecanismos podrán ser físicos o electrónicos:</p>

                            <ul>
                                <li>Físicos: Trámite de ventanilla.</li>
                                <li>Electrónicos: A través del correo electrónico.</li>
                            </ul>

                            <p>Dirección física para trámites: Carrera 27 A No. 66-30 Oficina 1005 C.C Sancancio en la ciudad de Manizales.</p>
                            <p>Teléfonos para recibir peticiones, quejas y reclamos.</p>

                            <p>Cualquiera que sea el medio, la sociedad guardará prueba de la consulta y su respuesta. Los pasos para la presentación de consultas son los siguientes:</p>

                            <ul>
                                <li>Las solicitudes se deberán formular por escrito.</li>
                                <li>La solicitud será analizada para verificar la identificación del Titular. Si la solicitud es formulada por persona distinta del Titular, y no se acredita que actúa en representación de éste, la solicitud será rechazada.</li>
                                <li>Si el solicitante tiene capacidad para formular la consulta, la sociedad recopilará toda la información sobre el Titular contenida en la Base de Datos de la sociedad.</li>
                                <li>La persona asignada para atender la consulta dará respuesta dentro de los diez (10) días hábiles contados a partir de la fecha en la que la solicitud fue recibida.</li>
                                <li>En todo caso, la respuesta definitiva no tardará más de quince (15) días hábiles desde la fecha en la que la solicitud inicial fue recibida.</li>
                            </ul>

                            <h3>RECLAMOS</h3>
                            <p>La sociedad dispone de mecanismos para que el Titular, sus causahabientes, representantes y/o apoderados, formulen reclamos respecto de:</p>

                            <ul>
                                <li>Datos Personales tratados por la sociedad que deben ser objeto de corrección, actualización, supresión o revocatoria de la Autorización.</li>
                                <li>El presunto incumplimiento de los deberes de Ley por parte de la sociedad.</li>
                            </ul>

                            <p>Estos mecanismos podrán ser físicos o electrónicos:</p>

                            <ul>
                                <li>Físicos: Trámite de ventanilla.</li>
                                <li>Electrónicos: A través del correo de Servicio al Cliente.</li>
                                <li>Teléfonos para recibir peticiones, quejas y reclamos.</li>
                            </ul>

                            <p>Cualquiera que sea el medio, la sociedad guardará prueba del reclamo y su respuesta. Los pasos para la presentación de reclamos son los siguientes:</p>

                            <ul>
                                <li>El reclamo se formulará mediante solicitud escrita.</li>
                                <li>La solicitud será analizada para verificar la identificación del Titular. Si la solicitud es formulada por persona distinta del Titular y no se acredita que actúa en representación de éste, la solicitud será rechazada.</li>
                                <li>El reclamo debe contener la siguiente información:
                                    <ul>
                                        <li>Nombre y número de documento de identificación del Titular.</li>
                                        <li>Datos de contacto (dirección física, electrónica y teléfonos de contacto).</li>
                                        <li>Documentos que acrediten la identidad del Titular o la representación de su representante.</li>
                                        <li>Descripción clara y precisa de los Datos Personales respecto de los cuales se busca ejercer algún derecho.</li>
                                        <li>Descripción de los hechos que dan lugar al reclamo y el objetivo perseguido (actualización, corrección, revocatoria, supresión, o cumplimiento de deberes).</li>
                                        <li>Documentos que se quieran hacer valer.</li>
                                        <li>Firma, correo electrónico, nombre y número de identificación del solicitante del reclamo.</li>
                                    </ul>
                                </li>
                                <li>Si el reclamo o la documentación adicional están incompletos, la sociedad requerirá al reclamante por una sola vez dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Si el reclamante no presenta la documentación e información requerida dentro de los dos (2) meses siguientes a la fecha del reclamo inicial, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.</li>
                                <li>Si por cualquier hecho la persona que recibe el reclamo al interior de la sociedad no es competente para resolverlo, dará traslado al Analista de Servicio al Cliente dentro de los dos (2) días hábiles siguientes a haber recibido el reclamo, e informará de dicha remisión al reclamante.</li>
                                <li>Una vez recibido el reclamo con la documentación completa, se incluirá en la Base de Datos de la sociedad donde reposen los Datos del Titular sujetos a reclamo una leyenda que diga “reclamo en trámite” y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Esta leyenda deberá mantenerse hasta que el reclamo sea decidido.</li>
                                <li>El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día hábil siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</li>
                            </ul>

                            <h2>9. SEGURIDAD DE LA INFORMACIÓN</h2>
                            <p>En desarrollo del principio de seguridad, la sociedad ha adoptado medidas técnicas, administrativas y humanas razonables para proteger los Datos Personales de los Titulares e impedir adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. El acceso a los datos personales está restringido a sus Titulares y a las personas autorizadas por la sociedad de acuerdo con esta Política. La sociedad no permitirá el acceso a esta información por parte de terceros en condiciones diferentes a las anunciadas, a excepción de un pedido expreso del Titular o de las personas legitimadas de conformidad con la normatividad nacional.</p>
                            <p>Es importante tener en cuenta que internet es una red global de comunicación que implica la transmisión de información en una red mundial. En este sentido, pese a que la sociedad cuenta con medidas necesarias para la protección de los Datos Personales, es posible que los mismos se vean afectados por las fallas propias de internet.</p>

                            <h2>10. VIGENCIA</h2>
                            <p>Esta Política rige a partir del 01 de octubre de 2024. Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en nuestra Base de Datos, con base en el criterio de temporalidad y necesidad, durante el tiempo que sea necesario para las finalidades mencionadas en esta Política y la respectiva Autorización, para las cuales fueron recolectados.</p>

                            <h2>11. MODIFICACIONES</h2>
                            <p>Esta Política podrá ser modificada por la sociedad cuando así lo requiera sin previa notificación, siempre que se trate de modificaciones no sustanciales. De lo contrario, serán comunicadas previamente a los Titulares.</p>

                            <h2>12. MARCO JURÍDICO</h2>
                            <ul>
                                <li>Artículo 15 de la Constitución Política de Colombia. Derecho a su intimidad personal y familiar y a su buen nombre.</li>
                                <li>Circular Única de la Superintendencia de Industria y Comercio.</li>
                                <li>Ley 1581 de 2012. Por la cual se dictan disposiciones generales para la protección de datos personales.</li>
                                <li>Decreto 1074 de 2015. Por el cual se reglamenta parcialmente la Ley 1581 de 2012.</li>
                                <li>Circular 886 de 2014. Por la cual se reglamenta el artículo 25 de la Ley 1581 de 2012, relativo al Registro Nacional de Bases de Datos.</li>
                                <li>Circular Externa 02 de 2015. Por la cual la Superintendencia de Industria y Comercio impartió instrucciones a los responsables del tratamiento de datos personales.</li>
                                <li>Decreto 1074 de 2015. Por medio del cual se expide el Decreto Único Reglamentario del Sector Comercio, Industria y Turismo.</li>
                            </ul>

                            <h2>13. CONTACTO</h2>
                            <p>Para cualquier consulta, solicitud o ejercicio de derechos en relación con sus datos personales, los titulares pueden ponerse en contacto con el Delegado de Protección de Datos a través de los siguientes canales de comunicación:</p>
                            <ul>
                                <li>Para correo físico: Carrera 27 A No. 66-30 Oficina 1005 C.C Sancancio en la ciudad de Manizales .</li>
                                
                                <li>Teléfono: 8911707</li>
                            </ul>
                        </section>

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDataModal(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalTratDatos;
