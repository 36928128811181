import { Routes, Route } from 'react-router-dom';
import Home from './home/home.js';
import { NavLink } from 'react-router-dom';
import { Header } from './shared/components/header.js';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
}

function NoMatch() {
    return (
        <>
            <div className='Header'>
                <center><Header /></center>
            </div>
            <section class="page_404">
                <div>
                    <div class="four_zero_four_bg">
                        <h1>404</h1>
                    </div>
                    <div class="box_404">
                        <h3>
                            <center>Pagina No encontrada</center>
                        </h3>
                    </div>
                    <center>
                        <button className='boton404'>
                            <NavLink to="/" style={{ textDecoration: 'none', color: '#ffffff' }}>
                                Ir al Menú
                            </NavLink>
                        </button>
                    </center>
                </div>
            </section>
        </>
    );
}

export default AppRoutes;
